import React from "react"
import Layout from '../components/Layout'
import Helmet from "react-helmet"
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { useSiteMetadata } from "../hooks/use-site-metadata"
import favicon from "../../static/favicon.svg"

export default function Home() {
  const { t } = useTranslation('404');
  const { title } = useSiteMetadata();
  return (
    <Layout>
	  	<Helmet>
		  <title>{title}</title>
            <link rel="icon" href={favicon} />
			<meta name="title" content="{title}" />
        </Helmet>
        <div className="espacio-blanco"></div>
        <article className={"articulo index text-justify"}>
			<h1>{t("Página no encontrada")}</h1>
        </article>
    </Layout>
  )
}
